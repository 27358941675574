







































































import { mapActions, mapGetters } from 'vuex'
import SignContainerComponent from '@/components/common/SignContainerComponent/SignContainerComponent.vue'
import ConfirmEmailComponent from '@/components/common/ConfirmEmailComponent.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { errorMessageRenderer } from '@/utils/utils'
import { Checkbox } from '../molecules/Checkbox'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'SignUp',
  components: {
    SignContainerComponent,
    ConfirmEmailComponent,
    MarkdownComponent,
    Checkbox,
    TextField,
  },
  inject: ['enums'],
  data() {
    return {
      email: '',
      password: '',
      confirmSignUpForm: {
        code: '',
      },
      signUpForm: {
        email: '',
        password: '',
        termsAndConditions: false,
        sendEmailNotifications: true,
      },
      confirmSignUpRules: {
        code: [
          {
            required: true,
            message: this.$t('signUp.form.validation.code.required'),
            trigger: 'blur',
          },
          {
            pattern: /^[0-9]{6}$/,
            message: this.$t('signUp.form.validation.code.pattern'),
            trigger: 'blur',
          },
        ],
      },
      signUpFormRules: {
        email: [
          {
            required: true,
            message: this.$t('signUp.form.validation.email.required'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('signUp.form.validation.email.type'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('signUp.form.validation.password.required'),
            trigger: 'blur',
          },
          {
            pattern: /^.{6,}$/,
            message: this.$t('signUp.form.validation.password.pattern'),
            trigger: 'blur',
          },
        ],
        termsAndConditions: {
          required: true,
          message: this.$t(
            'signUp.form.validation.termsAndConditions.required'
          ),
          trigger: 'change',
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isSignedUp: 'getIsSignedUp',
    }),
    title() {
      return this.isSignedUp
        ? this.$t('signUp.titleConfirm')
        : this.$t('signUp.title')
    },
  },
  created() {
    const { invite } = this.$route.query

    if (invite) {
      this.signUpForm.email = invite.replace(/\s/g, '+')
    }
  },
  destroyed(): void {
    this.$message.closeAll()
  },
  methods: {
    ...mapActions('auth', ['signUp', 'confirmSignUp', 'signIn']),
    async onSignUp() {
      await this.$refs.signUpForm.validate(async (valid: boolean) => {
        if (valid) {
          try {
            await this.$message.closeAll()
            const { email, password, sendEmailNotifications } = this.signUpForm
            await this.signUp({
              email: email.toLowerCase(),
              password,
              sendEmailNotifications,
            })
          } catch (error) {
            throw new Error(errorMessageRenderer(error))
          }
        }
      })
    },
    onSignInLink() {
      this.$router.push({ name: 'signIn' })
    },
  },
}
